* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
body,
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins/poppins-bold.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Teko-Regular";
  src: url("../fonts/Teko/Teko-Regular.ttf");
}

@font-face {
  font-family: "Teko-Bold";
  src: url("../fonts/Teko/Teko-Bold.ttf");
}

@font-face {
  font-family: "Teko-Medium";
  src: url("../fonts/Teko/Teko-Medium.ttf");
}

@font-face {
  font-family: "Teko-SemiBold";
  src: url("../fonts/Teko/Teko-SemiBold.ttf");
}

.user-head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  /* margin-top: 10px; */
}

.form-group .form-control input,
.form-control-details-auth .icon-position-rel input {
  background-color: initial;
  border: 1px solid #d1d5db !important;
  border-radius: 14px !important;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
  /* padding: 11.45px 16px !important; */
  width: 100%;
}
.join-wait-input input {
  padding: 7px 15px !important;
  border-radius: 4px !important;
  min-width: 400px !important;
  max-width: 400px;
}
.join-field input {
  max-width: 400px;
}

.table-icon-flex .table-icon-btn {
  min-width: 30px;
  margin-right: 2px;
  padding: 0px;
}
.table-view-icons {
  height: 20px;
  width: 20px;
  object-fit: contain;
}
.table-icon-flex {
  display: flex;
  align-items: center;
}

.merchant-user-table-main .table .table-th:nth-child(1),
.merchant-user-table-main .table .table-td:nth-child(1) {
  /* width: 16% !important; */
  width: 5% !important;
}

.merchant-user-table-main .table .table-th:nth-child(2),
.merchant-user-table-main .table .table-td:nth-child(2) {
  width: 15% !important;
}

.merchant-user-table-main .table .table-th:nth-child(3),
.merchant-user-table-main .table .table-td:nth-child(3) {
  width: 12% !important;
}

.merchant-user-table-main .table .table-th:nth-child(4),
.merchant-user-table-main .table .table-td:nth-child(4) {
  width: 15% !important;
}

.merchant-user-table-main .table .table-th:nth-child(5),
.merchant-user-table-main .table .table-td:nth-child(5) {
  width: 18% !important;
}

.merchant-user-table-main .table .table-th:nth-child(6),
.merchant-user-table-main .table .table-td:nth-child(6) {
  width: 15% !important;
}

.merchant-user-table-main .table .table-th:nth-child(7),
.merchant-user-table-main .table .table-td:nth-child(7) {
  width: 8% !important;
}

.merchant-user-table-main .table .table-th:nth-child(8),
.merchant-user-table-main .table .table-td:nth-child(8) {
  width: 12% !important;
}
.merchant-user-table-main .table-view-toggle-btn {
  display: flex;
}
.merchant-table-main .merchant-user-table-main .table .table-th:nth-child(8) {
  text-align: center !important;
}

.merchant-userdetails-table-main .table .table-th:nth-child(1),
.merchant-userdetails-table-main .table .table-td:nth-child(1) {
  width: 10% !important;
}
.merchant-userdetails-table-main .table .table-th:nth-child(2),
.merchant-userdetails-table-main .table .table-td:nth-child(2) {
  width: 2% !important;
}
.merchantdetails-table-main {
  width: 400px;
}
/* .admin-dashboard-box .admin-page-title {
  color: #5d5a68;
  font-size: 22px;
  line-height: 35px;
  font-family: "Poppins-SemiBold";
  /* margin-bottom: 20px; 
} */

.admin-dashboard-inner-box {
  /* border: 1px solid #D4F6FE;  */
  max-width: 100%;
  width: 100%;
  /* / height: 90px;  */
  /* / border-radius: 6px; / */
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 15px;
  border-radius: 6px;
}
.admin-bucinessInfo-inner-box {
  max-width: 100%;
  width: 100%;
  /* / height: 90px;  */
  /* / border-radius: 6px; / */
  padding: 15px;
  /* display: flex; */
  /* justify-content: space-around; */
  align-items: center;
  border-radius: 6px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 15px;
}
.user-details .basic-card-detail p {
  font-size: 15px;
}
.user-details .basic-card-detail p:nth-child(2) {
  font-size: 14px;
  font-family: "Poppins-Regular";
  color: #5d5a68 !important;
}
/* loader start */
.loader-main {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9999;
}
.loader-img {
  width: 100px;
}

.loader-overlay {
  pointer-events: none;
  overflow: hidden;
}

/* loader end */

/* admin back button css start */

.admin-dashboard-box-header-main{display: flex;
}
.admin-dashboard-box-header-main .admin-back-icon-main {
  background: black;
  color: white;
  padding: 7px;
  margin: 0px 10px 5px 0px;
}
.admin-dashboard-box-header-main .admin-back-icon-main:hover{
  background: black;
    color: white;
   
}

.admin-dashboard-box-header-main .admin-back-icon-main .admin-back-icon{
  height: 0.7em;
  width: 0.7em;
}

.admin-dashboard-box-header-main .admin-page-title {
  color: #5d5a68;
  font-size: 22px;
  line-height: 35px;
  font-family: "Poppins-SemiBold";
  
}

/* admin back button css end */

.profile-header {
  height: 30px;
  /* margin-bottom: 20px; */
  position: relative;
  width: 30px;
  display: flex;
}
.profile-input-box {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.profile-img {
  border-radius: 50%;
  height: 30px;
  object-fit: cover;
  width: 30px;
}

.profile-input-box .profile-upload-btn {
  display: none;
}
.profile-edit-main {
  bottom: 0px;
  cursor: pointer;
  position: absolute;
  right: 0;
}
.edit-div {
  align-items: center;
  background: #ededed;
  border: 1px solid #d1d5db;
  border-radius: 50%;
  display: flex;
  height: 10px;
  justify-content: center;
  width: 10px;
}
.profile-edit-img {
  height: 8px;
  width: 8px;
}
.token-name{
  margin: 7px;
}
.token-image{
  display: flex;
}